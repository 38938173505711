import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, LightMode, Stack, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { NavButtons, NavLinks } from './_data'
import { ChevronDownIcon } from '@chakra-ui/icons'

export const MobileDrawer = (props) => (
    <LightMode>
        <Drawer placement="right" {...props}>
            <DrawerContent px={2}>
                <DrawerCloseButton size="sm" mt={3} mr={3.5} />
                <DrawerBody pt={16}>
                    <Stack spacing="0" align="stretch">
                        {[...NavLinks, ...(NavButtons?.filter(i => i?.isLink) || [])]?.map((item) => {
                            return <NavLinksComponent key={item?.label} item={item} showArrow onClose={props.onClose} />
                        })}
                        <Stack mt={3} spacing={3} direction="row" w="full" justify="space-between">
                            {NavButtons?.filter(i => !i?.isLink)?.map((item, i) => (
                                <Button key={item?.label} variant={i === 0 ? "outline" : null} w="50%">{item?.label}</Button>
                            ))}
                        </Stack>
                    </Stack>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    </LightMode>
)

const NavLinksComponent = ({ item, showArrow, onClose }) => {
    return (
        <Accordion key={item?.label} allowMultiple rounded="md" defaultIndex={item?.isHeading ? [...Array(item?.children).keys()] : []} mx={showArrow ? 0 : item?.children ? -4 : 0}>
            <AccordionItem border="none">
                <Button
                    as={item?.href ? Link : AccordionButton}
                    w="full"
                    justifyContent="space-between"
                    href={item?.href}
                    size="lg"
                    variant="text"
                    fontWeight="medium"
                    _hover={{ bgColor: "gray.50" }}
                    borderRadius={5}
                    p={2.5}
                    pl={item?.isHeading ? 6 : item?.underHeading ? 6 : 2.5}
                    colorScheme="gray"
                    rightIcon={showArrow ? (item?.children ? <ChevronDownIcon fontSize="2xl !important" /> : null) : null}
                    onClick={() => { item?.href ? onClose() : null }}
                >
                    {item?.label}
                </Button>
                {item?.columns ? item?.columns?.map(i => {
                    return (
                        <AccordionPanel key={i?.label} py={0}>
                            <NavLinksComponent item={i} onClose={onClose} />
                        </AccordionPanel>
                    )
                }) : item?.children && (
                    <AccordionPanel py={0}>
                        {item?.children?.map((i) => {
                            return <NavLinksComponent key={i?.label} item={{ ...i, underHeading: item?.isHeading ? true : false }} onClose={onClose} />
                        })}
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Accordion>
    )
}