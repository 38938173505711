import { Box } from '@chakra-ui/react'

export const Container = (props) => {
    return (
        <Box
            px={{ base: '6', md: '6', lg: '6' }}
            maxW='7xl'
            mx='auto'
            {...props}
        />
    )
}
