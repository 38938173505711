'use client'

import {
    Box,
    Button,
    ButtonGroup,
    DarkMode,
    HStack,
    useBreakpointValue,
    useDisclosure,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'

import { MobileDrawer } from './MobileNavbar'
import { ToggleButton } from './ToggleButton'
import { Logo } from "../../../../src/screens/Auth/SignupLogin/Components/Logo"
import Link from 'next/link'
import { Container } from '../Container'
import { animated, config, useSpring } from "react-spring";
import { useHideOnScrollDown } from './hooks/useHideOnScroll'
import { NavButtons, NavLinks } from './_data'
import { useRouter } from 'next/navigation'
import { MenuPopover } from './Popover'

const Navbar = () => {

    const mobileNavbar = useDisclosure()

    const isVisible = useHideOnScrollDown()

    const showDesktopNavbar = useBreakpointValue({ base: false, lg: true }, { ssr: true })

    const props = useSpring({
        to: { top: isVisible ? "0" : "-124px" },
        config: { ...config.wobbly, clamp: true }
    });

    return (
        <DarkMode>
            <animated.header style={{ ...props, position: showDesktopNavbar ? "sticky" : "unset", zIndex: 5, background: "#2b6cb0", borderBottomLeftRadius: "56px", borderBottomRightRadius: "56px" }}>
                <Box
                    zIndex={{ base: "auto", lg: "docked" }}
                    borderBottomRadius="56px"
                >
                    <Box as="section">
                        <Box borderBottomWidth="0px" position="relative" zIndex="tooltip">
                            <Container py={{ base: 5, md: 10 }}>
                                <HStack justify="space-between">
                                    <HStack spacing={12} w="full" justify={{ base: "space-between", lg: "initial" }}>
                                        <Link href="/" aria-label="Logo"><Logo /></Link>
                                        <HStack spacing="16" display={{ base: "none", md: "block" }}>
                                            <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="0">
                                                {NavLinks?.map((item, i) => {
                                                    const hasMenu = item?.children
                                                    if (!hasMenu) return <TriggerButton key={item?.label} item={item} />
                                                    if (hasMenu) return <MenuPopover key={item?.label} i={i} item={item} TriggerButton={TriggerButton} />
                                                })}
                                            </ButtonGroup>
                                        </HStack>
                                        <Box display={{ base: "block", md: "none" }}>
                                            <ToggleButton
                                                onClick={mobileNavbar.onToggle}
                                                isOpen={mobileNavbar.isOpen}
                                                aria-label="Open Menu"
                                            />
                                            <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
                                        </Box>
                                    </HStack>
                                    <HStack display={{ base: "none", md: "flex" }} maxH="33px" spacing={4}>
                                        {NavButtons?.map((item, i) => {
                                            if (item?.isLink) return <TriggerButton key={item?.label} item={item} />
                                            const isPrimary = i === NavButtons?.length - 1
                                            return <RoundedButton key={item?.label} item={item} isPrimary={isPrimary} />
                                        })}
                                    </HStack>
                                </HStack>
                            </Container>
                        </Box>
                    </Box>
                </Box>
            </animated.header>
        </DarkMode>
    )
}

const TriggerButton = ({ item, isOpen } = {}) => {
    const router = useRouter()
    const hasMenu = item?.children
    return (
        <Button
            key={item?.label}
            variant="ghost"
            fontSize="lg"
            fontWeight="medium"
            textDecor="underline"
            textUnderlineOffset={6}
            textDecorationColor="transparent"
            color="fg.default"
            transition="text-decoration 250ms ease-in-out 0s"
            _hover={{ bgColor: "whiteAlpha.200", textDecorationColor: "transparent" }}
            h={11}
            iconSpacing={2}
            pr={hasMenu ? 2.5 : 4}
            rightIcon={
                hasMenu
                    ?
                    <ChevronDownIcon
                        transition='transform 0.4s'
                        transform={`rotate(${isOpen ? 18 : ''}0deg)`}
                    />
                    :
                    null
            }
            onClick={() => {
                if (!hasMenu) router.push(item?.href)
            }}
        >
            {!hasMenu ?
                <Link
                    href={item?.href}
                    target={item?.target || "_self"}
                    rel="noopener noreferrer"
                >
                    {item?.label}
                </Link>
                :
                item?.label}
        </Button>
    )
}

const RoundedButton = ({ item, isPrimary }) => {
    const router = useRouter()
    return (
        <Button
            size="xl"
            borderRadius="3xl"
            px={7}
            color={isPrimary ? "blue.600" : "white"}
            bgColor={isPrimary ? "white" : "whiteAlpha.200"}
            _hover={{ bgColor: "white", color: "blue.600" }}
            onClick={() => { router.push(item?.href) }}
        >
            <Link href={item.href} target="_self">
                {item?.label}
            </Link>
        </Button>
    )
}

export default Navbar