import { theme } from '@chakra-ui/pro-theme'
import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
// no shadows for dark mode
Object.keys(theme.semanticTokens.shadows).map(size => { theme.semanticTokens.shadows[size]._dark = null; });

const myTheme = (extend) => extendTheme({
    colors: {
        ...baseTheme.colors,
        brand: baseTheme.colors.blue
    },
    semanticTokens: {
        ...theme.semanticTokens,
        colors: {
            ...theme.semanticTokens.colors,
            brand: theme.colors.blue,
            'rarchy-bg-upgrade-modal': { default: 'gray.700', _dark: 'gray.800' },
            'rarchy-bg-toast': { default: 'gray.700', _dark: 'gray.300' },
            'rarchy-bg-toast-button': { default: 'gray.600', _dark: 'gray.200' },
            'rarchy-color-toast': { default: 'whiteAlpha.900', _dark: 'gray.900' },
            'rarchy-bg-white': { default: 'white', _dark: 'gray.700' },
            'rarchy-bg-dark': { default: 'gray.800', _dark: 'gray.800' },
            'rarchy-bg-black': { default: 'black', _dark: 'gray.700' },
            'rarchy-bg-sitemap-editor': { default: 'white', _dark: 'gray.800' },
            'rarchy-bg-sitemap-editor-subtle': { default: 'white', _dark: 'blackAlpha.400' },
            'rarchy-bg-gray25': { default: 'gray.25', _dark: 'gray.800' },
            'rarchy-bg-subtle': { default: 'gray.50', _dark: 'whiteAlpha.50' },
            'rarchy-bg-subtle-hover': { default: 'gray.100', _dark: 'whiteAlpha.100' },
            'rarchy-bg-subtle-hover-dark': { default: 'whiteAlpha.100', _dark: 'whiteAlpha.100' },
            'rarchy-color-file': { default: 'gray.100', _dark: 'whiteAlpha.200' },
            'rarchy-bg-subtle-hover-200': { default: 'gray.200', _dark: 'whiteAlpha.200' },
            'rarchy-bg-subtle-hover-200-dark': { default: 'whiteAlpha.200', _dark: 'whiteAlpha.200' },
            'rarchy-color-folder': { default: 'gray.200', _dark: 'whiteAlpha.300' },
            'rarchy-badge-icon': { default: 'gray.300', _dark: 'whiteAlpha.400' },
            'rarchy-badge-icon-dark': { default: 'whiteAlpha.400', _dark: 'whiteAlpha.400' },
            'rarchy-border': { default: 'gray.200', _dark: 'gray.600' },
            'rarchy-border-dark': { default: 'gray.600', _dark: 'gray.600' },
            'rarchy-border-sitemap-editor': { default: 'gray.200', _dark: 'gray.700' },
            'rarchy-border-400': { default: 'gray.400', _dark: 'gray.600' },
            'rarchy-color-sitemap-editor': { default: 'gray.600', _dark: 'whiteAlpha.800' },
            'rarchy-blue': { default: 'blue.500', _dark: 'blue.200' },
            'rarchy-blue-dark': { default: 'blue.200', _dark: 'blue.200' },
            'rarchy-orange-dark': { default: 'orange.400', _dark: 'orange.400' },
            'rarchy-red': { default: 'red.500', _dark: 'red.300' },
            'rarchy-yellow': { default: 'yellow.400', _dark: 'yellow.500' },
            'rarchy-upgrade-color': { default: "#ECC94B", _dark: "#ECC94B" },
            'rarchy-green': { default: 'green.400', _dark: 'green.500' },
            'rarchy-link': { default: 'blue.500', _dark: 'blue.300' },
            'rarchy-chakra-react-select-border': { default: 'gray.300', _dark: 'whiteAlpha.400' },
            'rarchy-border-accordion': { default: 'gray.300', _dark: 'whiteAlpha.300' },
        },
    },
    fonts: {
        heading: 'Inter, sans-serif',
        body: 'Inter, sans-serif',
    },
    config: {
        initialColorMode: typeof window !== 'undefined' ? localStorage.getItem("chakra-ui-color-mode-system") ? "system" : "light" : "light",
        useSystemColorMode: typeof window !== 'undefined' ? localStorage.getItem("chakra-ui-color-mode-system") ? true : false : false,
    },
    components: {
        Modal: {
            parts: ['dialog', 'overlay', 'closeButton'],
            baseStyle: (props) => {
                const prefix = props.colorMode === "light" ? 'black' : 'white';
                const numberPrefix = props.colorMode === "light" ? 6 : 4;
                return {
                    dialog: {
                        boxShadow: 'none'
                    },
                    overlay: {
                        backdropFilter: 'blur(2px)'
                    },
                    closeButton: {
                        position: 'absolute',
                        top: 0,
                        right: '-42px',
                        bg: `${prefix}Alpha.${numberPrefix}00`,
                        _hover: {
                            bg: `${prefix}Alpha.${numberPrefix + 1}00`,
                        },
                        fontSize: '10px',
                        color: 'white',
                        rounded: "full"
                    }
                }
            }
        },
        Avatar: {
            parts: ['container', 'excessLabel'],
            sizes: {
                smd: {
                    container: {
                        w: "40px", h: "40px"
                    }
                },
                gx: { // lg -> xl
                    container: {
                        w: "80px", h: "80px", fontSize: "2.25rem"
                    }
                },
                xxs: {
                    container: {
                        w: "22px", h: "22px", fontSize: "0.6rem"
                    },
                    excessLabel: {
                        w: "19px",
                        h: "19px",
                        fontSize: "0.66rem",
                        ml: -1.5
                    }
                },
                xs: {
                    container: {
                        w: "26px", h: "26px"
                    },
                    excessLabel: {
                        w: "23px",
                        h: "23px",
                        fontSize: "xs",
                        ml: -1.5
                    }
                },
            },
            baseStyle: (props) => ({
                container: {
                    // background: props.colorMode === "light" ? "gray.300" : "gray.600",
                    // borderColor: props.colorMode === "light" ? "white" : "gray.700",
                    _hover: { cursor: 'pointer' }
                }
            }),
        },
        Input: {
            baseStyle: {
                field: {
                    _placeholder: { color: "gray.400" }
                }
            }
        },
        Textarea: {
            baseStyle: {
                _placeholder: { color: "gray.400" }
            }
        },
        Alert: {
            parts: ['container'],
            baseStyle: {
                container: {
                    py: 2,
                    borderRadius: 3,
                    fontSize: "sm",
                    fontWeight: "medium"
                }
            },
            variants: {
                info: {
                    container: {
                        background: "rarchy-bg-subtle-hover"
                    }
                }
            }
        },
        Editable: {
            variants: {
                filled: (props) => ({
                    preview: {
                        noOfLines: 1,
                        // w: 'full',
                        px: 3,
                        py: 1.5,
                        background: 'rarchy-bg-subtle',
                        _hover: {
                            m: 0,
                            background: 'rarchy-bg-subtle-hover',
                            rounded: 'md',
                            transition: 'none'
                        }
                    },
                    input: {
                        px: 3,
                        py: 1.5,
                        _focus: {
                            ml: '-1px',
                            mt: '-1px',
                            mb: '-1px',
                            transition: 'none',
                            boxShadow: 'none',
                            border: '1px solid',
                            borderColor: 'rarchy-border'
                        },
                    }
                }),
                flushed: (props) => ({
                    preview: {
                        w: "fit-content",
                        noOfLines: 1,
                        rounded: "none",
                        borderBottom: "1px dashed",
                        borderColor: "transparent",
                        _hover: {
                            borderBottom: "1px dashed",
                            borderBottomColor: 'rarchy-border-400'
                        }
                    },
                    input: {
                        noOfLines: 1,
                        rounded: "none",
                        borderBottom: "1px dashed",
                        borderBottomColor: 'rarchy-border-400'
                    }
                })
            }
        },
        Table: {
            parts: ['table', 'th', 'tr'],
            variants: {
                rounded: (props) => ({
                    table: {
                        display: "block",
                        rounded: "md",
                        borderWidth: "1px"
                    },
                    tr: {
                        borderBottom: "1px",
                        borderColor: "rarchy-bg-subtle",
                        "&:last-of-type": {
                            display: "contents",
                            borderBottomLeftRadius: "md",
                            borderBottomRightRadius: "md",
                        }
                    },
                    th: {
                        "&:first-of-type": {
                            borderTopLeftRadius: "md",
                        },
                        "&:last-of-type": {
                            borderTopRightRadius: "md",
                        }
                    }
                })
            }
        },
        Calendar: {
            parts: ['calendar'],
            baseStyle: {
                calendar: {
                    width: "full",
                    borderColor: "gray.200",
                    boxShadow: "none"
                    // borderWidth: '0',
                },
            },
        },
        CalendarControl: {
            parts: ['button'],
            baseStyle: {
                button: {
                    h: 6,
                    px: 2,
                    rounded: 'md',
                    fontSize: 'lg',
                    color: 'gray.700',
                    bgColor: 'background',

                    _hover: {
                        bgColor: 'gray.100',
                    },

                    _focus: {
                        outline: 'none',
                    }
                }
            }
        },
        Drawer: {
            variants: {
                alwaysOpen: {
                    dialog: {
                        pointerEvents: 'auto',
                    }
                }
            },
            baseStyle: {
                overlay: { bg: "blackAlpha.300" },
                dialog: {
                    "&:focus:not([data-focus-visible-added])": {
                        shadow: "lg" // default shadow
                    },
                },
            },
        },
        Text: {
            variants: {
                'sitemaps-row-header': {
                    fontSize: "13px",
                    fontWeight: "medium",
                    color: "gray.500"
                }
            }
        },
        Tooltip: {
            variants: {
                rounded: {
                    p: 2,
                    py: 0,
                    rounded: "md",
                    fontSize: "xs"
                },
                'rounded-sm': {
                    rounded: "4px",
                    fontSize: "xs",
                    py: 1,
                    my: 0.5
                }
            }
        },
        MenuList: {
            variants: {
                noShadow: {
                    shadow: "none",
                }
            }
        },
    }
}, extend)

export default myTheme