import {
    Box,
    ChakraProvider,
    LightMode,
    cookieStorageManagerSSR,
    localStorageManager,
} from '@chakra-ui/react'

import { GoogleTagManager } from '@next/third-parties/google'
import Navbar from '@/-components/Navbar'
import NextNProgress from 'nextjs-progressbar'
import { PricingProvider } from "../../src/screens/Components/PricingProvider"
import SEO from "@/-sections/SEO"
import createCache from '@emotion/cache';
import myAppTheme from "../../src/theme"
import { theme } from '@chakra-ui/pro-theme'

const emotionCache = createCache({
    key: 'emotion-css-cache',
    prepend: true, // ensures styles are prepended to the <head>, instead of appended
});

export function Chakra({ cookies, children }) {
    const colorModeManager =
        typeof cookies === 'string'
            ? cookieStorageManagerSSR(cookies)
            : localStorageManager

    return (
        <ChakraProvider theme={myAppTheme(theme)} colorModeManager={colorModeManager} value={emotionCache}>
            <GoogleTagManager gtmId="GTM-WDJZXNF" />
                <PricingProvider>
                    <LightMode>
                        <Box w="full" h="full" pos="fixed" bg="blue.600" zIndex={-1} />
                        <NextNProgress color="orange" height={2} options={{ showSpinner: false }} />
                        <SEO />
                        <Navbar />
                        {children}
                    </LightMode>
                </PricingProvider>
        </ChakraProvider>
    )
}

// also export a reusable function getServerSideProps
export function getServerSideProps({ req }) {
    return {
        props: {
            // first time users will not have any cookies and you may not return
            // undefined here, hence ?? is necessary
            cookies: req.headers.cookie ?? '',
        },
    }
}