import { chakra, useColorModeValue as mode } from '@chakra-ui/react'

export const Logo = ({ w, inSideMenu }) => {
    const fill = mode(inSideMenu ? "white" : "#323E48", "white");
    return (
        <chakra.svg xmlns="http://www.w3.org/2000/svg" width={w || "150px"} height="44px" viewBox="0 0 1000 251" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <path id="path-1" d="M0.06,0.346 L1000,0.346 L1000,250.94 L0.06,250.94" />
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rarchy_Brand_Logo_RGB_Main">
                    <g id="Group-4">
                        <mask id="mask-2" fill="white">
                            <use href="#path-1" />
                        </mask>
                        <g id="Clip-2" />
                        <path d="M53.657,55.337 C53.657,55.12 53.684,54.909 53.69,54.693 C53.704,54.22 53.719,53.749 53.761,53.279 C53.792,52.91 53.843,52.546 53.892,52.181 C53.943,51.801 53.996,51.422 54.065,51.045 C54.143,50.623 54.238,50.208 54.337,49.794 C54.414,49.477 54.491,49.16 54.581,48.846 C54.705,48.412 54.844,47.986 54.991,47.563 C55.095,47.265 55.2,46.969 55.316,46.675 C55.476,46.27 55.648,45.872 55.83,45.478 C55.973,45.165 56.121,44.856 56.277,44.549 C56.456,44.199 56.646,43.856 56.843,43.516 C57.039,43.177 57.24,42.843 57.453,42.513 C57.64,42.222 57.835,41.939 58.035,41.657 C58.286,41.304 58.543,40.958 58.814,40.617 C59.011,40.369 59.215,40.129 59.422,39.889 C59.715,39.55 60.013,39.218 60.326,38.895 C60.555,38.659 60.79,38.433 61.028,38.207 C61.336,37.915 61.645,37.627 61.972,37.35 C62.265,37.099 62.569,36.862 62.875,36.626 C63.165,36.403 63.452,36.18 63.754,35.968 C64.143,35.696 64.545,35.444 64.951,35.195 C65.133,35.083 65.301,34.956 65.486,34.848 L77.316,28.018 L35.494,3.872 C24.177,-2.663 9.707,1.215 3.174,12.531 C-3.359,23.848 0.517,38.317 11.833,44.851 L53.657,68.997 L53.657,55.337" id="Fill-1" fill="#2ED9C3" mask="url(#mask-2)" />
                        <path d="M151.459,12.531 C144.926,1.215 130.456,-2.662 119.14,3.872 L65.486,34.848 C65.301,34.956 65.133,35.083 64.951,35.195 C64.545,35.444 64.143,35.696 63.754,35.968 C63.452,36.18 63.165,36.403 62.875,36.626 C62.569,36.862 62.265,37.099 61.972,37.35 C61.645,37.627 61.336,37.915 61.028,38.207 C60.79,38.433 60.555,38.659 60.326,38.895 C60.013,39.218 59.715,39.55 59.422,39.889 C59.215,40.129 59.011,40.369 58.814,40.617 C58.543,40.958 58.286,41.304 58.035,41.657 C57.835,41.939 57.64,42.222 57.453,42.513 C57.24,42.843 57.039,43.177 56.843,43.516 C56.646,43.856 56.456,44.199 56.277,44.549 C56.121,44.856 55.973,45.165 55.83,45.478 C55.648,45.872 55.476,46.27 55.316,46.675 C55.2,46.969 55.095,47.265 54.991,47.563 C54.844,47.986 54.705,48.412 54.581,48.846 C54.491,49.16 54.414,49.477 54.337,49.794 C54.238,50.208 54.143,50.623 54.065,51.045 C53.996,51.422 53.943,51.801 53.892,52.181 C53.843,52.546 53.792,52.91 53.761,53.279 C53.719,53.749 53.704,54.22 53.69,54.693 C53.684,54.909 53.657,55.12 53.657,55.337 L53.657,116.401 C53.657,129.468 64.249,140.061 77.316,140.061 C90.383,140.061 100.976,129.468 100.976,116.401 L100.976,68.997 L142.799,44.851 C154.115,38.317 157.993,23.848 151.459,12.531" id="Fill-3" fill="#00A7E1" mask="url(#mask-2)" />
                    </g>
                    <path d="M291.401,64.721 C284.129,61.586 276.091,60.125 266.104,60.125 C255.879,60.125 246.431,62.919 238.352,68.197 C237.312,64.989 234.299,62.669 230.745,62.669 L210.268,62.669 C205.852,62.669 202.273,66.249 202.273,70.664 L202.273,191.493 C202.273,195.909 205.852,199.488 210.268,199.488 L230.999,199.488 C235.415,199.488 238.994,195.909 238.994,191.493 L238.994,124.21 C238.994,106.704 247.968,95.829 262.415,95.829 C268.342,95.829 275.161,97.342 279.385,99.594 C281.559,100.754 284.146,100.846 286.397,99.844 C288.648,98.842 290.311,96.859 290.905,94.467 L295.993,73.99 C296.937,70.194 294.993,66.271 291.401,64.721" id="Fill-5" fill={fill} />
                    <path d="M375.864,166.583 C356.397,166.583 342.268,151.65 342.268,131.078 C342.268,110.249 356.083,95.702 375.864,95.702 C395.57,95.702 409.333,110.249 409.333,131.078 C409.333,151.983 395.57,166.583 375.864,166.583 L375.864,166.583 Z M436.915,62.669 L416.056,62.669 C412.036,62.669 408.708,65.636 408.145,69.5 C398.285,63.325 386.55,60.125 373.447,60.125 C334.447,60.125 305.038,90.628 305.038,131.078 C305.038,171.528 334.447,202.031 373.447,202.031 C386.534,202.031 398.272,198.842 408.149,192.689 C408.727,196.537 412.047,199.488 416.056,199.488 L436.915,199.488 C441.331,199.488 444.91,195.909 444.91,191.493 L444.91,70.664 C444.91,66.249 441.331,62.669 436.915,62.669 L436.915,62.669 Z" id="Fill-6" fill={fill} />
                    <path d="M565.154,64.721 C557.884,61.586 549.845,60.125 539.858,60.125 C529.634,60.125 520.185,62.919 512.105,68.197 C511.066,64.989 508.053,62.669 504.499,62.669 L484.022,62.669 C479.606,62.669 476.027,66.249 476.027,70.664 L476.027,191.493 C476.027,195.909 479.606,199.488 484.022,199.488 L504.753,199.488 C509.169,199.488 512.748,195.909 512.748,191.493 L512.748,124.21 C512.748,106.704 521.723,95.829 536.169,95.829 C542.096,95.829 548.916,97.342 553.14,99.594 C555.313,100.754 557.901,100.846 560.151,99.844 C562.403,98.842 564.065,96.859 564.66,94.467 L569.748,73.99 C570.691,70.194 568.748,66.271 565.154,64.721" id="Fill-7" fill={fill} />
                    <g id="Group-12" transform="translate(573.000000, 0.000000)" fill={fill}>
                        <path d="M122.364,169.758 L109.518,155.386 C108.067,153.764 106.018,152.802 103.842,152.724 C101.668,152.655 99.554,153.459 97.991,154.975 C90.271,162.46 80.685,166.583 70.996,166.583 C51.897,166.583 38.036,151.65 38.036,131.078 C38.036,110.58 51.897,95.702 70.996,95.702 C81.093,95.702 89.507,99.308 97.478,107.051 C99.012,108.541 101.081,109.356 103.217,109.309 C105.356,109.264 107.387,108.364 108.856,106.81 L122.21,92.693 C125.036,89.706 125.136,85.065 122.442,81.96 C110.229,67.88 92.14,60.125 71.505,60.125 C30.54,60.125 0.806,89.965 0.806,131.078 C0.806,172.191 30.54,202.031 71.505,202.031 C91.942,202.031 110.012,194.346 122.385,180.39 C125.077,177.355 125.067,172.783 122.364,169.758" id="Fill-8" />
                        <path d="M211.309,60.125 C199.412,60.125 188.707,62.964 179.875,68.323 L179.875,8.34 C179.875,3.925 176.296,0.346 171.88,0.346 L151.149,0.346 C146.733,0.346 143.153,3.925 143.153,8.34 L143.153,191.493 C143.153,195.909 146.733,199.488 151.149,199.488 L171.88,199.488 C176.296,199.488 179.875,195.909 179.875,191.493 L179.875,123.701 C179.875,104.447 193.81,95.829 207.621,95.829 C228.45,95.829 232.822,110.709 232.822,123.192 L232.822,191.493 C232.822,195.909 236.401,199.488 240.817,199.488 L261.676,199.488 C266.092,199.488 269.671,195.909 269.671,191.493 L269.671,116.451 C269.671,83.287 245.672,60.125 211.309,60.125" id="Fill-10" />
                        <path d="M425.911,66.637 C424.479,64.18 421.85,62.669 419.005,62.669 L396.111,62.669 C393.21,62.669 390.536,64.241 389.125,66.777 L354.598,128.819 L318.175,66.624 C316.74,64.174 314.114,62.669 311.275,62.669 L288.001,62.669 C285.142,62.669 282.501,64.195 281.073,66.673 C279.646,69.15 279.65,72.2 281.084,74.673 L333.837,165.683 L291.507,239.009 C290.077,241.482 290.078,244.529 291.507,247.003 C292.935,249.476 295.573,251 298.43,251 L321.451,251 C324.332,251 326.989,249.45 328.408,246.943 L425.963,74.602 C427.36,72.127 427.34,69.094 425.911,66.637" id="Fill-11" />
                    </g>
                </g>
            </g>
        </chakra.svg>
    )
}