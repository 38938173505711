export const NavLinks = [
    {
        label: 'Products', children: [
            {
                label: 'Visual Sitemaps', sublabel: "Plan your website structure", href: "/sitemaps"
            },
            {
                label: 'Visual Sitemap Generator', sublabel: "Visualize an existing website", href: "/sitemaps/visual-sitemap-generator"
            }
            ,
            {
                label: 'User Flows', sublabel: "Design sophisticated user journeys", href: "/user-flow"
            }
        ]
    },
    {
        label: 'Solutions',
        children: [
            {
                label: 'For Agencies', href: "/solutions/agencies"
            }
        ]
    },
    {
        label: 'Demos', /* columns: [
            {
                label: 'Visual Sitemaps',
                isHeading: true,
                children: [
                    {
                        label: "Demo Sitemap", sublabel: 'Our fully-featured demo', isExternal: true, href: ""https://rarchy.com/app/sitemap/fuy2mjJWjKIof5oiacCs"
                    },
                    {
                        label: "Large Sitemap", sublabel: 'A 25,000 pages sitemap', isExternal: true, href: ""https://rarchy.com/app/sitemap/pCqbPjVeDyNDvBD59syn"
                    },
                    {
                        label: "Digital Agency Template", sublabel: 'Showcasing page blocks', isExternal: true, href: ""https://rarchy.com/app/sitemap/kTJH1jkyCpG6IMlNLapq"
                    }
                ]
            },
            {
                label: 'User Flows',
                isHeading: true,
                children: [
                    { label: "Basic Sitemap", href: "" },
                    { label: "25,000 Pages", href: "" },
                    { label: "Digital Agency Template", href: "" }
                ]
            }
        ] , */ children: [
            {
                label: "Demo Sitemap",
                sublabel: 'Our fully-featured demo',
                isExternal: true,
                href: "https://rarchy.com/app/sitemap/fuy2mjJWjKIof5oiacCs"
            },
            {
                label: "Large Sitemap",
                sublabel: 'A 25,000 pages sitemap',
                isExternal: true,
                href: "https://rarchy.com/app/sitemap/pCqbPjVeDyNDvBD59syn"
            },
            {
                label: "Digital Agency Template",
                sublabel: 'Showcasing page blocks',
                isExternal: true,
                href: "https://rarchy.com/app/sitemap/kTJH1jkyCpG6IMlNLapq"
            }
        ]
    },
    // { label: 'Support', href: "/support" }, // just for now, so we can get it out
    { label: 'Pricing', href: "/pricing" },
]

export const NavButtons = [
    { label: 'Support', href: "/support", isLink: true },
    { label: 'Login', href: '/app?navbar=1' },
    { label: 'Sign Up', href: '/app/signup?navbar=1' }
]