import Head from 'next/head'
import pathnameMap from "./_data"
import { usePathname } from 'next/navigation'
import { useRouter } from 'next/router'

export default function SEO(/*{ title, description, name, type }*/) {

    const router = useRouter()

    const pathname = usePathname()
    let { title, description, name, type } = pathnameMap[pathname] || {}

    // default
    if (!title) title = 'Rarchy'

    const canonicalUrl = (`https://rarchy.com` + (router.asPath === "/" ? "" : router.asPath)).split("?")[0];

    return (
        <Head>
            { /* Standard metadata tags */}
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel="canonical" href={canonicalUrl} />
            { /* End standard metadata tags */}
            { /* Facebook tags */}
            {/* <meta property="og:type" content={type} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} /> */}
            { /* End Facebook tags */}
            { /* Twitter tags */}
            {/* <meta name="twitter:creator" content={name} />
            <meta name="twitter:card" content={type} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} /> */}
            { /* End Twitter tags */}
        </Head>
    )
}

