import { useState } from "react"
import { allowed_currencies } from "../../src/screens/Components/PricingProvider"

const useLocalStorage = (key, initialValue) => {
    const [state, setState] = useState(() => {
        // Initialize the state
        try {
            const value = typeof window !== 'undefined' ? window.localStorage.getItem(key) : null
            // Check if the local storage already has any values,
            // otherwise initialize it with the passed initialValue
            // currency specific
            if (key === 'currency') return allowed_currencies?.includes((value || "")?.toLowerCase()) ? value?.toLowerCase() : initialValue
            return value ? JSON.parse(value) : initialValue
        } catch (error) {
            // console.log(error)
            return initialValue
        }
    })

    const setValue = value => {
        try {
            // If the passed value is a callback function,
            //  then call it with the existing state.
            const valueToStore = value instanceof Function ? value(state) : value
            if (typeof window !== 'undefined') window.localStorage.setItem(key, valueToStore)
            setState(value)
        } catch (error) {
            // console.log(error)
        }
    }

    return [state, setValue]
}

export default useLocalStorage