import { createContext, useEffect, useState } from "react"

import { useBoolean } from "@chakra-ui/react"
import useLocalStorage from "../../../shared/hooks/useLocalStorage"

export const allowed_currencies = ['usd', 'gbp', 'eur', 'aud', 'nzd']

export const PricingContext = createContext()

export const PricingProvider = ({ children }) => {
    const [pricing, setPricing] = useState(fallbackDocData)
    const [loadedPricing, setLoadedPricing] = useBoolean()
    const [billingInterval, setBillingInterval] = useState('month')
    const [selectedCurrency, setSelectedCurrency] = useLocalStorage("currency", "usd")
    // pricing doc from firestore
    useEffect(() => {
        async function fetchPricing() {
            const { getFirestore, getDoc, doc } = await import("firebase/firestore/lite")
            const docRef = doc(getFirestore(), 'rarchy', 'pricing')
            try {
                const docData = await getDoc(docRef)
                setPricing(docData?.exists() ? docData?.data() : fallbackDocData)
            } catch (e) {
                setPricing(fallbackDocData)
            } finally {
                // set pricing as loaded
                setLoadedPricing.on()
                const queryString = await import('query-string')
                // now we've got pricing, popup upgrade modal post stripe redirect
                const { session_id, email, orgId, orgName, orgWebsite, planId, priceInUSD, billingInterval, confirm, isRestoring } = queryString.parse(window.location.search);
                const stripeRedirectAttrs = { email, orgId, orgName, orgWebsite, priceInUSD, billingInterval, isRestoring }
                if (session_id) {
                    if (!isRestoring || (isRestoring && confirm)) {
                        if (!process.env.isNext) { // HAS TO BE THIS, not isInMarketing
                            window.history.pushState(null, '', `${window.location.origin}${window.location.pathname}`); // clear parameters
                            const { store } = await import('../../store/')
                            const { toggleUpgradeModal } = await import("../../store/actions/ui-actions")
                            store.dispatch(toggleUpgradeModal({ showing: true, planId, stripeRedirectAttrs, screen: confirm ? 'confirming' : 'details' }))
                        }
                    }
                }
            }
        }
        if (!process.env.isNext) if (!loadedPricing) fetchPricing()
    }, [])

    // set currency in localstorage
    useEffect(() => {
        // clear storage on mount (for testing)
        // localStorage.removeItem("currency")
        if (typeof window !== 'undefined' && !localStorage.getItem('currency')) {
            fetch('https://ipapi.co/json/')
                .then(function (response) {
                    response.json().then(jsonData => {
                        if (jsonData) {
                            let currency = jsonData?.currency || 'usd';
                            if (allowed_currencies?.includes(currency?.toLowerCase())) {
                                setSelectedCurrency(currency?.toLowerCase())
                            }
                        }
                    });
                })
                .catch(function (error) {
                    console.log(error)
                });
        }
    }, [])

    return (
        <PricingContext.Provider
            value={{
                pricing,
                billingInterval,
                setBillingInterval,
                currencies,
                selectedCurrency,
                setSelectedCurrency
            }}
        >
            {children}
        </PricingContext.Provider>
    )

}

// Update "Lookup key" in Stripe products
// "updateRarchyPricing" function takes care of using lookup key to update pricing in Firestore Rarchy doc
const fallbackDocData = {
    "options": {
        "is_trial_enabled": false,
        "trial_days": 14
    },
    "plans": {
        "freelance": {
            "limits": {
                "screenshots": 1000
            }
        },
        "agency": {
            "prices": {
                "aud": 349, // 79
                "usd": 249, // 59,
                "eur": 209, // 51,
                "gbp": 189, // 47,
                "nzd": 349, // 79
            },
            "id": "rarchy-agency-multi-249",
            "limits": {
                "pages_per_import": 25000,
                "screenshots": 25000,
                "seats": "Unlimited"
            }
        },
        "free": {
            "limits": {
                "screenshots": 50,
                "seats": 1,
                "pages_per_import": 50
            }
        },
        "team": {
            "limits": {
                "pages_per_import": 5000,
                "seats": 5,
                "screenshots": 5000
            },
            "id": "rarchy-team-multi-49",
            "prices": {
                "aud": 69,
                "usd": 49,
                "eur": 41,
                "gbp": 39,
                "nzd": 69,
            }
        },
        "pro": {
            "limits": {
                "pages_per_import": 500,
                "seats": 1,
                "screenshots": 500
            },
            "id": "rarchy-pro-multi-15",
            "prices": {
                "aud": 20,
                "usd": 15,
                "eur": 13,
                "gbp": 12,
                "nzd": 20
            }
        }
    },
    "add_ons": {
        "seats": {
            "id": "rarchy-seats-multi-9",
            "prices": {
                "aud": 12, // 20
                "usd": 9, // 15
                "eur": 7, // 13
                "gbp": 6, // 12
                "nzd": 12, // 20
            }
        }
    }
}

const currencies = {
    'usd': { code: 'USD', ISO: 'US', symbol: '$' },
    'eur': { code: 'EUR', ISO: 'EU', symbol: '€' },
    'gbp': { code: 'GBP', ISO: 'GB', symbol: '£' },
    'aud': { code: 'AUD', ISO: 'AU', symbol: '$' },
    'nzd': { code: 'NZD', ISO: 'NZ', symbol: '$' },
}