import { Box, Button, LightMode, Popover, PopoverBody, PopoverContent, PopoverTrigger, Portal, Stack, Text } from "@chakra-ui/react"
import { ArrowForwardIcon } from "@chakra-ui/icons"
import Link from "next/link"
import { useState } from "react"
import { useRouter } from "next/router"

const transition = "opacity 100ms ease-in-out, margin 150ms ease-in-out;"

export const MenuPopover = ({ i, item, TriggerButton }) => {
    return (
        <Popover matchWidth>
            {({ isOpen, onClose }) => (
                <>
                    <PopoverTrigger>
                        {TriggerButton({ item, isOpen })}
                    </PopoverTrigger>
                    <LightMode>
                        <Portal>
                            <PopoverContent w="fit-content">
                                <PopoverBody>
                                    <Stack align="start" p={3} spacing={5}>
                                        {item?.columns
                                            ?
                                            <MenuColumns columns={item?.columns} onClose={onClose} />
                                            :
                                            <MenuItem onClose={onClose}>{item?.children}</MenuItem>
                                        }
                                    </Stack>
                                </PopoverBody>
                            </PopoverContent>
                        </Portal>
                    </LightMode>
                </>
            )}
        </Popover>
    )
}

const MenuColumns = ({ columns, onClose }) => {
    return (
        <Stack direction="row" w="max-content" spacing={10} pb={3}>
            {columns?.map(column => {
                return (
                    <Stack key={column?.label} align="start" spacing={5}>
                        <Text textTransform="normal" fontSize="md" color="fg.default" fontWeight="bold">
                            {column?.label}
                        </Text>
                        <MenuItem onClose={onClose}>{column?.children}</MenuItem>
                    </Stack>
                )
            })}
        </Stack>
    )
}

const MenuItem = ({ children, onClose, ...props }) => {
    const [showArrow, setShowArrow] = useState()
    const router = useRouter()
    return children?.map((child, j) => {
        const isHovered = showArrow === j
        return (
            <Button
                key={j}
                variant="link"
                textDecor="none !important"
                color={isHovered ? "rarchy-link" : "fg.default"}
                onMouseEnter={() => setShowArrow(j)}
                onMouseLeave={() => setShowArrow()}
                onMouseUp={onClose}
                onClick={() => {
                    if (child?.href) router?.push(child?.href)
                }}
                fontWeight={child?.sublabel ? "medium" : "normal"}
            >
                <Stack align="start" spacing={1.5}>
                    <Stack direction="row" align="center" spacing={0} color={isHovered ? "rarchy-link" : "fg.default"} textDecor={isHovered ? "underline" : "inherit"}>
                        <Link href={child?.href} target={child?.isExternal ? "_blank" : "_self"} color="fg.default">
                            {child?.label}
                        </Link>
                        <ArrowForwardIcon
                            mt="2px"
                            ml={`${isHovered ? 4 : 0}px`}
                            opacity={isHovered ? "1" : "0"}
                            fontSize="sm !important"
                            transition={transition}
                        />
                        <Box mr={`${isHovered ? -2 : 2}px`} transition={transition} />
                    </Stack>
                    {child?.sublabel && <Text fontSize="sm" fontWeight="normal" color="fg.muted">{child?.sublabel}</Text>}
                </Stack>
            </Button>
        )
    })
}