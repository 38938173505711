import { IconButton } from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'

export const ToggleButton = (props) => {
    const { isOpen, ...iconButtonProps } = props
    return (
        <IconButton
            variant="unstyled"
            display="inline-flex"
            icon={<HamburgerIcon color="white" fontSize="27px !important" />}
            {...iconButtonProps}
        />
    )
}